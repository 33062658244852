import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Store } from "@node_modules/@ngrx/store";
import { MapModel } from "@app/state-management/models/map.model";
import { Observable } from "@node_modules/rxjs";
import {
    BaseComponentEnums,
    ComponentDto,
    ComponentsServiceProxy,
    DashboardMode,
    IotDataResponse,
    PowerPlantDto,
    PowerPlantsServiceProxy,
    PowerPlantType,
    ReportDashboardServiceProxy,
    SolarDeviceType,
    WidgetUnitType,
} from "@shared/service-proxies/service-proxies";
import * as _ from "lodash";
import { BaseComponentConsts } from "@app/main/report-trials/WidgetConsts";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { DateTimeService } from "@app/shared/common/timing/date-time.service";
import { setMapState } from "@app/state-management/actions/map.action";
import { MapService } from "@app/shared/services/map.service";
import { MapConstants } from "@app/main/map/map.const";

@Component({
    selector: "app-company-drawer",
    templateUrl: "./company-drawer.component.html",
    styleUrls: ["./company-drawer.component.css"],
})
export class CompanyDrawerComponent extends AppComponentBase implements OnInit {
    protected readonly PowerPlantType = PowerPlantType;

    technologyBasedProductionValues = new Map<PowerPlantType, string>();

    mapReducer: Observable<string>;

    isCompanyDrawerOpen: boolean = false;
    public currentInstantPower: string;
    public totalCurrentInstantPower: string;
    private unitComponents: ComponentDto[];
    private tenantName: string;
    powerPlants: PowerPlantDto[];
    tenantAllCapacity: number;
    totalTenantAllCapacity: string;
    public instantPercentage: number;
    public totalDailyProduction: number;
    public totalDailyPercentage: number;
    public powerPlantTypes: PowerPlantType[] = [];
    public currentAllWindPower: string;
    public windPowerPlantsCapacity: number;
    public currentAllHydroPower: string;
    public hydroPowerPlantsCapacity: number;
    public currentAllSolarPower: string;
    public solarPowerPlantsCapacity: number;

    public totalDailyWindProduction: number = 0;
    public totalDailySolarProduction: number = 0;
    public totalDailyHydroProduction: number = 0;

    public readonly KwhToMwh = 1000;
    private stateModel: MapModel;
    public currentTotalNetGenerationValue: string;
    private allUnitComponents: ComponentDto[];
    public isHybridPowerPlant: boolean = false;

    constructor(
        injector: Injector,
        private mapStore: Store<MapModel>,
        private _componentService: ComponentsServiceProxy,
        private _powerPlantService: PowerPlantsServiceProxy,
        private _localStorageService: LocalStorageService,
        private _reportDashboardServiceProxy: ReportDashboardServiceProxy,
        private _dateTimeService: DateTimeService,
        private _mapService: MapService
    ) {
        super(injector);
    }

    protected readonly _ = _;

    async ngOnInit() {
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.TenancyName,
            (tName) => (this.tenantName = tName)
        );
        await this._mapService.getLocalStorageItemAsync(
            MapConstants.LocalStorageKeys.PowerPlantList,
            (cache) => (this.powerPlants = cache)
        );

        await this._mapService.getLocalStorageItemAsync(MapConstants.LocalStorageKeys.ComponentList, (cache) => {
            this.unitComponents = cache.filter((x) => x.baseComponentId === BaseComponentConsts.Power);
            this.allUnitComponents = cache;
        });

        // @ts-ignore
        this.mapReducer = this.mapStore.select("mapReducer");
        this.mapReducer.subscribe((mapModel: any) => {
            this.isCompanyDrawerOpen = mapModel.data.isCompanyViewActive;
            this.stateModel = mapModel.data;
        });

        this.subscribeToEvent("iot-data-all-schema", (realtimeIotData: IotDataResponse) => {
            this.getWindProduction(realtimeIotData);
            this.getHydroProduction(realtimeIotData);
            this.getSolarProduction(realtimeIotData);
            this.GetTotalNetGenerationForHybridPowerPlant(realtimeIotData);
            this.getInstantPower();
        });

        await this.getAllPowerPlants();

        this.getDailyTotalProduction();
    }

    getAllPowerPlants = () => {
        return new Promise((resolve, reject) => {
            this.tenantAllCapacity = _.sumBy(this.powerPlants, (x) => x.capacity);
            _.forEach(this.powerPlants, (value) => {
                this.powerPlantTypes.push(value.type);
            });
            this.windPowerPlantsCapacity = _.sumBy(
                this.powerPlants.filter((x) => x.type === PowerPlantType.Wind),
                (x) => x.capacity / this.KwhToMwh
            );
            this.hydroPowerPlantsCapacity = _.sumBy(
                this.powerPlants.filter((x) => x.type === PowerPlantType.Hydro),
                (x) => x.capacity / this.KwhToMwh
            );
            this.solarPowerPlantsCapacity = _.sumBy(
                this.powerPlants.filter((x) => x.type === PowerPlantType.Solar),
                (x) => x.capacity / this.KwhToMwh
            );
            resolve(this.powerPlants);
        });
    };

    // get all turbines production
    getInstantPower() {
        if (_.isNil(this.currentAllWindPower) || _.isNil(this.currentAllSolarPower) || _.isNil(this.currentAllHydroPower)) {
            return;
        }

        this.currentInstantPower = _.round(
            Number(this.currentAllWindPower) + Number(this.currentAllSolarPower) + Number(this.currentAllHydroPower),
            2
        ).toFixed(2);
        this.totalCurrentInstantPower = _.round(this.tenantAllCapacity / this.KwhToMwh, 2).toFixed(2);
        this.instantPercentage = _.toNumber(
            _.round((Number(this.currentInstantPower) * 100) / (this.tenantAllCapacity / this.KwhToMwh))
        );
    }

    getWindProduction(data: IotDataResponse) {
        let windPowerPlants = this.powerPlants.filter((x) => x.type === PowerPlantType.Wind);

        let filteredUuidList = this.unitComponents.filter(
            (x) => x.baseComponentId == BaseComponentConsts.Power && x.powerPlantUnitId !== 0
        );

        const windPowerPlantsAllPowerComponentValues = filteredUuidList.filter((elem) =>
            windPowerPlants.find((x) => x.id == elem.powerPlantId)
        );

        const powerValues = data.values.filter((elem) =>
            windPowerPlantsAllPowerComponentValues.find(
                (x) =>
                    (this.tenantName !== "lodos" ? x.opcItemId : x.uuid) == elem.uuid.toString() &&
                    x.baseComponentId === BaseComponentConsts.Power
            )
        );

        let sumValue = _.sumBy(powerValues, (x) => x.value / this.KwhToMwh);

        this.currentAllWindPower = _.round(sumValue, 2).toFixed(2);
    }

    getHydroProduction(data: IotDataResponse) {
        let hydroPowerPlants = this.powerPlants.filter((x) => x.type === PowerPlantType.Hydro);

        let filteredUuidList = this.unitComponents.filter(
            (x) => x.baseComponentId == BaseComponentConsts.Power && x.powerPlantUnitId !== 0
        );

        const hydroPowerPlantsAllPowerComponentValues = filteredUuidList.filter((elem) =>
            hydroPowerPlants.find((x) => x.id == elem.powerPlantId)
        );

        const powerValues = data.values.filter((elem) =>
            hydroPowerPlantsAllPowerComponentValues.find(
                (x) =>
                    (this.tenantName !== "lodos" ? x.opcItemId : x.uuid) == elem.uuid.toString() &&
                    x.baseComponentId === BaseComponentConsts.Power
            )
        );

        let sumValue = _.sumBy(powerValues, (x) => x.value / this.KwhToMwh);

        this.currentAllHydroPower = _.round(sumValue, 2).toFixed(2);
    }

    getSolarProduction(data: IotDataResponse) {
        let solarPowerPlants = this.powerPlants.filter((x) => x.type === PowerPlantType.Solar);

        let filteredUuidList = this.unitComponents.filter(
            (x) => x.baseComponentId == BaseComponentConsts.Power && x.solarDeviceType == SolarDeviceType.Inverter
        );

        const solarPowerPlantsAllPowerComponentValues = filteredUuidList.filter((elem) =>
            solarPowerPlants.find((x) => x.id == elem.powerPlantId)
        );

        const powerValues = data.values.filter((elem) =>
            solarPowerPlantsAllPowerComponentValues.find(
                (x) => x.opcItemId.toString() == elem.uuid.toString() && x.baseComponentId === BaseComponentConsts.Power
            )
        );

        let sumValue = _.sumBy(powerValues, (x) => x.value / this.KwhToMwh);

        this.currentAllSolarPower = _.round(sumValue, 2).toFixed(2);
    }

    getDailyTotalProduction() {
        this.totalDailyProduction = 0;
        let localTotalProduction = 0;
        _.forEach(this.powerPlants, (powerPlant) => {
            this._reportDashboardServiceProxy
                .getProduction(
                    powerPlant.type,
                    powerPlant.id,
                    this.getConnectionStringSecretName(),
                    powerPlant.schemaName,
                    undefined,
                    this._dateTimeService.getStartOfDayForDate(this._dateTimeService.getDate()),
                    this._dateTimeService.getEndOfDayForDate(this._dateTimeService.getDate()),
                    powerPlant.utcOffset,
                    WidgetUnitType.M,
                    DashboardMode.TimeBasedDaily,
                    undefined
                )
                .subscribe((response) => {
                    if (!response || response.errorMessage) {
                        return;
                    }

                    if (_.isNil(response)) {
                        return;
                    }

                    localTotalProduction += response.total;
                    let _powerTotal = _.round(response.total / this.KwhToMwh, 2);
                    this.totalDailyProduction += _powerTotal;

                    this.totalDailyProduction = _.round(this.totalDailyProduction, 2);
                    let _percentage = (localTotalProduction * 100) / (this.tenantAllCapacity * 24);
                    if (_percentage < 1) {
                        _percentage = 1;
                    }
                    this.totalTenantAllCapacity = _.round((this.tenantAllCapacity / this.KwhToMwh) * 24, 2).toFixed(2);
                    this.totalDailyPercentage = _.round(_percentage);

                    if (powerPlant.type === PowerPlantType.Wind) {
                        this.totalDailyWindProduction += _powerTotal;
                    }
                    if (powerPlant.type === PowerPlantType.Solar) {
                        this.totalDailySolarProduction += _powerTotal;
                    }
                    if (powerPlant.type === PowerPlantType.Hydro) {
                        this.totalDailyHydroProduction += _powerTotal;
                    }
                });
        });
    }

    closeCompanyView() {
        this.isCompanyDrawerOpen = !this.isCompanyDrawerOpen;
        this.mapStore.dispatch(
            setMapState({
                isCompanyViewActive: this.isCompanyDrawerOpen,
                isActiveViewActive: this.stateModel.isActiveViewActive,
            })
        );
    }

    private GetTotalNetGenerationForHybridPowerPlant(realtimeIotData: IotDataResponse) {
        let totalNetGenerationTag = this.allUnitComponents.find(
            (x) => x.baseComponentId == BaseComponentEnums.TotalNetGeneration
        );

        if (!totalNetGenerationTag) {
            return;
        }

        this.isHybridPowerPlant = true;

        const powerValues = realtimeIotData.values.find(
            (iotData) => iotData.uuid.toString() == totalNetGenerationTag.opcItemId.toString()
        );

        let totalNetGenerationValue = powerValues.value / this.KwhToMwh;

        this.currentTotalNetGenerationValue = _.round(totalNetGenerationValue, 2).toFixed(2);
    }
}
